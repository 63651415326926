import { useContext } from "react";

const useApiEndPoint = () => {
  // const baseUrl = "http://localhost:5000/api/v1/user";
  const baseUrl = "/api/v1/user";
  const dashboardEndPoint = "/";
  const loginEndPoint = "/login";
  const signupEndPoint = "/signup";
  const contactEndPoint = "/contact";
  const historyEndPoint = "/history";
  const historySearchEndPoint = "/history/search";
  const contactSearchEndPoint = "/contact/search";
  const getTwilioTokenEndPoint = "/token/twilio/call";
  const addUserToCallEndPoint = "/api/twilio/addUser";
  const makeCallEndPoint = "/make/call";
  const checkCall = "/check/call";
  const addPersonInConference = "/add/person/in/conference";
  const getSingleHistoryFromCallIdEndPoint = "/call/history/callId";

  const checkUpcomingCallLeadDataEndPoint = "/call/history/callId";

  return {
    baseUrl,
    dashboardEndPoint,
    loginEndPoint,
    signupEndPoint,
    contactEndPoint,
    historyEndPoint,
    historySearchEndPoint,
    contactSearchEndPoint,
    getTwilioTokenEndPoint,
    addUserToCallEndPoint,
    makeCallEndPoint,
    checkCall,
    getSingleHistoryFromCallIdEndPoint,
    addPersonInConference,
    checkUpcomingCallLeadDataEndPoint,
  };
};

export default useApiEndPoint;
