import * as React from 'react';
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Grid, Button, Dialog, Divider, Checkbox, Stack, TextField, Box, Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function BroadcastDialog(props) {
    const { open, setOpen } = props;

    const [showNew, setShowNew] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [desc, setDesc] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create a new broadcast"}</DialogTitle>
                <DialogContent>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 3, display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            Owner: <span style={{ fontWeight: "bold", marginLeft: "10px" }}>Mease Steve</span>
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 3, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Group:
                        </Typography>
                        <Select
                            value={"none"}
                            label=""
                            sx={{ minWidth: 150, mr: 3, maxHeight: 40 }}
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Operator:
                        </Typography>
                        <Select
                            value={"none"}
                            label=""
                            sx={{ minWidth: 150, mr: 3, maxHeight: 40 }}
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Script:
                        </Typography>
                        <Select
                            value={"none"}
                            label=""
                            sx={{ minWidth: 150, mr: 3, maxHeight: 40 }}
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                        </Select>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Channels:
                        </Typography>
                        <TextField
                            label=""
                            type="number"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"

                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Schedule:
                        </Typography>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Edit
                        </Button>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Remark:
                        </Typography>
                        <TextField
                            label=""
                            id="standard-size-small"
                            defaultValue=""
                            size="small"

                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Checkbox {...label} sx={{ minWidth: 80, display: "flex", justifyContent: "flex-end" }} />
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Enable Call Analysis Procedure
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 0 }}>
                        <Checkbox {...label} sx={{ minWidth: 80, display: "flex", justifyContent: "flex-end" }} />
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Automatically hangup
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Database of phone numbers:
                        </Typography>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Upload
                        </Button>
                        <Typography variant="subtitle1" component="h2" sx={{ ml: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            no calls
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Message for live voice:
                        </Typography>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Play Greeting
                        </Button>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Change Greeting
                        </Button>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 0, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Message for answering machine:
                        </Typography>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Play Greeting
                        </Button>
                        <Button variant="contained" sx={{ ml: 1, borderRadius: 2 }} size="small">
                            Change Greeting
                        </Button>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 4, justifyContent: "center" }}>
                        <Button variant="contained" color="error" sx={{ width: 120, mr: 1, borderRadius: 6 }} onClick={handleClose}>
                            Return
                        </Button>
                        <Button variant="contained" color="success" sx={{ width: 120, ml: 1, borderRadius: 6 }} onClick={() => handleClose()}>
                            Save
                        </Button>
                    </Stack>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
}