import * as React from "react";
import { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
  Button,
  Checkbox,
  Link,
} from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Smartphone from "@mui/icons-material/Smartphone";
import Email from "@mui/icons-material/Email";
import Key from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "./style.css";

import {
  useAppForm,
  SHARED_CONTROL_PROPS,
  eventPreventDefault,
  VALIDATION_PHONE,
} from "../../components/utils/form";
import useAuthApi from "../../api/useAuthApi";
import { useNavigate } from "react-router-dom";

const VALIDATE_FORM_LOGIN_EMAIL = {
  email: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 128,
      message: "address is required",
    },
    email: true,
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      maximum: 16,
      message: "must be between 8 and 16 digit",
    },
  },
  firstName: {
    presence: true,
    type: "string",
    length: {
      minimum: 3,
      maximum: 16,
      message: "is Required",
    },
  },
  lastName: {
    presence: true,
    type: "string",
    length: {
      minimum: 3,
      maximum: 32,
      message: "is Required",
    },
  },
  phone: {
    ...VALIDATION_PHONE,
    presence: true,
    type: "string",
    length: {
      minimum: 10,
      maximum: 32,
      message: "number is Required",
    },
  },

  confirmPassword: {
    equality: {
      attribute: "password",
      message: "does not match with password",
    },
    presence: true,
  },
};

export default function Register() {
  const navigate = useNavigate();
  const { signup } = useAuthApi();
  const [
    formState,
    ,
    /* setFormState */ onFieldChange,
    fieldGetError,
    fieldHasError,
  ] = useAppForm({
    validationSchema: VALIDATE_FORM_LOGIN_EMAIL,

    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);
  const handleShowConfirmPasswordClick = useCallback(() => {
    setShowConfirmPassword((oldValue) => !oldValue);
  }, []);

  const handleSignUp = async (e) => {
    const response = signup(values);
    if (response) navigate("/");
  };

  return (
    <Grid
      container
      // sx={{ flexDirection: { sm: "column", md: "row" } }}
      justifyContent="center"
      alignItems="center"
      className="full-layout-login"
    >
      <Card sx={{ minHeight: "60%", width: { sm: "100vw", md: "50vw" } }}>
        <CardHeader title="Sign up" style={{ textAlign: "center" }} />
        <CardContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                required
                fullWidth
                label="First name"
                name="firstName"
                value={values.firstName}
                inputProps={{ maxLength: 20 }}
                error={fieldHasError("firstName")}
                helperText={fieldGetError("firstName") || " "}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                // required
                label="Last name"
                name="lastName"
                value={values.lastName}
                inputProps={{ maxLength: 20 }}
                error={fieldHasError("lastName")}
                helperText={fieldGetError("lastName") || " "}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                required
                label="Phone Number"
                name="phone"
                error={fieldHasError("phone")}
                helperText={fieldGetError("phone") || " "}
                onChange={onFieldChange}
                inputProps={{ maxLength: 16 }}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="upload picture" component="label">
                        <LocalPhoneIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                required
                label="Email"
                name="email"
                error={fieldHasError("email")}
                helperText={fieldGetError("email") || " "}
                onChange={onFieldChange}
                inputProps={{ maxLength: 64 }}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="upload picture" component="label">
                        <Email />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                required
                type={showPassword ? "text" : "password"}
                label="Password"
                name="password"
                value={values.password}
                error={fieldHasError("password")}
                helperText={fieldGetError("password") || " "}
                inputProps={{ maxLength: 16 }}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="upload picture" component="label">
                        <Key />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        icon={showPassword ? "visibilityon" : "visibilityoff"}
                        title={showPassword ? "Hide Password" : "Show Password"}
                        onClick={handleShowPasswordClick}
                        onMouseDown={eventPreventDefault}
                      >
                        {!showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={6} sx={{ paddingX: "10px" }}>
              <TextField
                required
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                name="confirmPassword"
                inputProps={{ maxLength: 16 }}
                value={values.confirmPassword}
                error={fieldHasError("confirmPassword")}
                helperText={fieldGetError("confirmPassword") || " "}
                onChange={onFieldChange}
                {...SHARED_CONTROL_PROPS}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton aria-label="upload picture" component="label">
                        <Key />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        icon={
                          showConfirmPassword ? "visibilityon" : "visibilityoff"
                        }
                        title={
                          showConfirmPassword
                            ? "Hide Password"
                            : "Show Password"
                        }
                        onClick={handleShowConfirmPasswordClick}
                        onMouseDown={eventPreventDefault}
                      >
                        {!showConfirmPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Grid item sm={12} xs={12} md={6} lg={6}>
                <Button
                  type="button"
                  variant="contained"
                  disabled={!formState.isValid}
                  onClick={handleSignUp}
                  sx={{ width: "100%" }}
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12} md={6} lg={3}>
              <Link href="/login" variant="body2">
                {"Have an account? Log in"}
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
