import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

const IncomingCallModal = ({
  open,
  // handleClose,
  data,
  // setIsIncomingCall,
  leadData,
  acceptIncomingCall,
  rejectIncomingCall,
  // hangupIncomingCall,
  // index,
  // setIndex,
  // timestamp,
  // setTimestamp,
  // handleDisconnectedIncomingCall,
}) => {
  // let intervalID = "";
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* {index === 0 ? ( */}
        <Box>
          <Typography variant="h6" component="h2">
            Incoming Call From {data?.parameters?.From}
          </Typography>

          {leadData ? (
            <Grid container sx={{ padding: "30px" }}>
              <Grid item>
                <Typography>
                  Name: {leadData?.first_name} {leadData?.last_name}
                </Typography>
                <Typography>Phone Number: {leadData?.phone_number}</Typography>
                <Typography>Email: {leadData?.email}</Typography>
                <Typography>City: {leadData?.city}</Typography>
                <Typography>Gender: {leadData?.gender}</Typography>
                <Typography>Gender: {leadData?.gender}</Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Grid container sx={{ padding: "30px" }}>
            <Grid item sm={6}>
              <Button
                variant="contained"
                onClick={() => {
                  acceptIncomingCall(data);
                  // setIndex(1);
                  // intervalID = setInterval(
                  //   () => setTimestamp(timestamp + 1),
                  //   1000
                  // );
                }}
              >
                Accept Call
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                onClick={() => {
                  rejectIncomingCall(data);
                  // setIsIncomingCall(false);
                  // setIndex(0);
                }}
                sx={{ backgroundColor: "red" }}
                variant="contained"
              >
                Reject Call
              </Button>
            </Grid>
          </Grid>
        </Box>
        {/* ) : (
          <Box>
            <Typography variant="h6" component="h2">
              Call From {data?.parameters?.From}
            </Typography>
            <Grid container sx={{ padding: "30px" }}>
              <Grid item sm={6}>
                {timestamp}
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"
                  onClick={() => {
                    hangupIncomingCall(data);
                    setIndex(0);
                    clearInterval(intervalID);
                  }}
                  sx={{ backgroundColor: "red" }}
                >
                  HangUp
                </Button>
              </Grid>
            </Grid>
          </Box>
        )} */}
      </Box>
    </Modal>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default IncomingCallModal;
