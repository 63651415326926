import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader({ open, setOpen }) {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 10000000000000 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
