import { useContext } from "react";
import { callCenterContext } from "../context/callCenterContext";

const useCallCenter = () => {
    const {
        userStatus,
        setUserStatus,
        statusList,
        phoneMode,
        modeString,
        relatedStatus } = useContext(callCenterContext);
    return {
        relatedStatus,
        modeString,
        phoneMode,
        statusList,
        userStatus,
        setUserStatus
    };
};

export default useCallCenter;
