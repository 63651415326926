import React, {
  useState,
  useEffect,
  useRef,
  PropsWithChildren,
  useReducer,
} from "react";
import socketIOClient, { Socket, io } from "socket.io-client";
const initialState = { value: "a" };

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { value: action.payload };
    default:
      return state;
  }
};
export const SocketContext = React.createContext({});

export const SocketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [client, setClient] = useState(null);
  const [leadData, setLeadData] = useState();
  const [outgoingData, setOutgoingData] = useState();

  useEffect(() => {
    console.log("State updated:", state.value);
    // Your side effect code here
  }, [state.value]); // Dependency array ensures this effect runs only when state.value changes

  useEffect(() => {
    const initial = socketIOClient("http://localhost:5000", {
      // const initial = socketIOClient("/", {
      query: { token: localStorage.getItem("bearer") },
    });
    initial.on("nextGenLeadData", (data) => {
      setLeadData(data);
    });
    initial.on("outgoingCall", (data) => {
      console.log(data);
      setOutgoingData(data);
      dispatch({ type: "update", payload: data });
    });
    setClient(initial);
    return () => {
      initial.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        curSocket: client,
        leadData: leadData,
        setLeadData: setLeadData,
        outgoingData: outgoingData,
        setOutgoingData: setOutgoingData,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
