import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Container,
  Typography ,
  Grid,
} from '@mui/material';

const EmailParser = () => {
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform login logic here
  };

  return (
    < Grid
      container
      direction="column"
      className="full-layout"
    >
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Smtp Host URL"
            fullWidth
            value={host}
            onChange={(e) => setHost(e.target.value)}
            sx={{ marginBottom: '1rem', marginTop: '3rem' }}
          />
          <TextField
            label="Smtp port"
            fullWidth
            value={port}
            onChange={(e) => setPort(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Smtp Email Address"
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <TextField
            label="Smtp Email Password"
            type="Smtp Email Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: '1rem' }}
          />
          <Typography 
            variant="body1" 
            color="primary" 
            fontSize={16} 
            align="center"
            sx={{ marginBottom: '1rem' }}
          >
            {status}
          </Typography>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Start Email parser service 
          </Button>
        </form>
      </Container>
    </Grid>
  );
};

export default EmailParser;
