// import { baseUrl, dashboard, login } from "./useApiEndPoint";
import axios from "axios";
import useAuth from "../hook/useAuth";
// import { useNavigate } from "react-router-dom";
import useApiEndPoint from "./useApiEndPoint";

const useCallApi = () => {
  const {
    baseUrl,
    contactEndPoint,
    historyEndPoint,
    historySearchEndPoint,
    contactSearchEndPoint,
    getTwilioTokenEndPoint,
    addUserToCallEndPoint,
    makeCallEndPoint,
    checkCall,
    addPersonInConference,
    getSingleHistoryFromCallIdEndPoint,
    checkUpcomingCallLeadDataEndPoint,
  } = useApiEndPoint();

  const { setIsLoading, snackbar } = useAuth();

  const createContactApi = async ({ name, phone }) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseUrl}${contactEndPoint}`,
        { name, phone },
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      setIsLoading(false);
      snackbar("success", response.data.message);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      snackbar("error", error.response.data.message);
      return null;
    }
  };
  const getContactApi = async (page) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}${contactEndPoint}?page=${page}&limit=10`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );

      setIsLoading(false);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const editContactApi = async ({ contactId, name, phone }) => {
    try {
      setIsLoading(true);
      const response = await axios.put(
        `${baseUrl}${contactEndPoint}?contactId=${contactId}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        },
        { name, phone }
      );

      setIsLoading(false);
      snackbar("success", response.data.message);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      snackbar("error", error.response.data.message);
      return null;
    }
  };
  const deleteContactApi = async ({ contactId, name, phone }) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${baseUrl}${contactEndPoint}?contactId=${contactId}`,
        { name, phone },
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );

      setIsLoading(false);
      snackbar("success", response.data.message);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      snackbar("error", error.response.data.message);
      return null;
    }
  };
  const getCallHistoryApi = async (page) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}${historyEndPoint}?page=${page}&limit=10`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );

      setIsLoading(false);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const createCallHistoryApi = async (phone, name, userNumber, type) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseUrl}${historyEndPoint}`,
        { phone, name, userNumber, type },
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );

      setIsLoading(false);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const searchContactApi = async (query) => {
    try {
      const response = await axios.get(
        `${baseUrl}${contactSearchEndPoint}?query=${query}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  };
  const searchCallHistoryApi = async (query) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}${historySearchEndPoint}?query=${query}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };

  const getTwilioToken = async (query) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}${getTwilioTokenEndPoint}?`, {
        headers: { bearer: localStorage.getItem("bearer") },
      });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };

  const addUserToCall = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}${addUserToCallEndPoint}?`, {
        headers: { bearer: localStorage.getItem("bearer") },
      });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const makeCallApi = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${baseUrl}${makeCallEndPoint}`, data, {
        headers: { bearer: localStorage.getItem("bearer") },
      });
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const checkCallApi = async (sessionID) => {
    try {
      // setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}${checkCall}?sessionID=${sessionID}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      // setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const addPersonInConferenceApi = async (sessionID, number) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseUrl}${addPersonInConference}`,
        {
          sessionID,
          number,
        },
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      return null;
    }
  };
  const getSingleHistoryFromCallId = async (callId) => {
    try {
      const response = await axios.get(
        `${baseUrl}${getSingleHistoryFromCallIdEndPoint}?callId=${callId}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      return response.data;
    } catch (error) {
      return NaN;
    }
  };

  const checkUpcomingCallLeadData = async (callId) => {
    try {
      const response = await axios.get(
        `${baseUrl}${checkUpcomingCallLeadDataEndPoint}?callId=${callId}`,
        {
          headers: { bearer: localStorage.getItem("bearer") },
        }
      );
      return response.data;
    } catch (error) {
      return NaN;
    }
  };
  return {
    createContactApi,
    searchContactApi,
    getContactApi,
    editContactApi,
    deleteContactApi,
    getCallHistoryApi,
    createCallHistoryApi,
    searchCallHistoryApi,
    makeCallApi,
    getTwilioToken,
    checkCallApi,
    getSingleHistoryFromCallId,
    addPersonInConferenceApi,
    checkUpcomingCallLeadData,
  };
};

export default useCallApi;
