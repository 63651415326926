import * as React from 'react';
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Grid, Button, Dialog, Divider, Checkbox, Stack, TextField, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import ScheduledDialDialog from './ScheduledDial';
import BroadcastDialog from './Broadcast';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function DialModeDialog(props) {
    const { open, setOpen } = props;
    const [scheduledDlg, setScheduledDlg] = useState(false);
    const [broadcastDlg, setBroadcastDlg] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index == 2) {
            setScheduledDlg(true);
        }
        else if (index == 3) {
            setBroadcastDlg(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Select type of Outbound Call"}</DialogTitle>
                <DialogContent>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders">
                            <ListItemButton
                                selected={selectedIndex === 0}
                                onClick={(event) => handleListItemClick(event, 0)}
                            >
                                <ListItemText primary="Immediate Call" />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemText primary="Manual" />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndex === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <ListItemText primary="Scheduled" />
                            </ListItemButton>
                            <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}
                            >
                                <ListItemText primary="New Broadcasting" />
                            </ListItemButton>
                        </List>
                    </Box>
                    {
                        (selectedIndex == 0 || selectedIndex == 1) && <Divider sx={{ m: 2 }} />
                    }
                    {
                        selectedIndex == 0 && <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <TextField
                                label="Phone Number"
                                id="standard-size-small"
                                defaultValue=""
                                size="small"
                                variant="standard"
                            />
                            <Button variant="contained" color="primary" sx={{ borderRadius: 6 }}>
                                DIAL
                            </Button>
                        </Stack>
                    }
                    {
                        selectedIndex == 1 &&
                        <>
                            <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <TextField
                                    label="Phone Number"
                                    id="standard-size-small"
                                    defaultValue=""
                                    size="small"
                                    variant="standard"
                                />
                                <Button variant="contained" color="primary" sx={{ borderRadius: 6 }}>
                                    DIAL
                                </Button>
                            </Stack>
                            <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around", mt: 4 }}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Groups</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value="outbound_group"
                                    >
                                        <FormControlLabel value="outbound_group" control={<Radio />} label="Outbound Group" />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Operators</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Mease Steve" />
                                        <FormControlLabel value="male" control={<Radio />} label="Henry Philip" />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                        </>
                    }
                    {selectedIndex == 2 && <ScheduledDialDialog open={scheduledDlg} setOpen={setScheduledDlg} />}
                    {selectedIndex == 3 && <BroadcastDialog open={broadcastDlg} setOpen={setBroadcastDlg} />}
                </DialogContent>
            </Dialog>
        </div>
    );
}