import * as React from "react";
import logo from "../../assets/img/logo.png";
import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "./listItem/ListItem";
import List from "@mui/material/List";
import useContent from "./listItem/useContent";
import CollapseItem from "./listItem/CollapseItem";
import { authContext } from "../../context/authContext";
import Avatar from "@mui/material/Avatar";
import Moment from "react-moment";

import {
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Navbar from "../NavBar";
import { Link } from "react-router-dom";
import Clock from "clock-react";

const drawerWidth = 270;

function LeftDrawer(props) {
  const Content = useContent();
  const [expanded, setExpanded] = React.useState("");

  const handleExpandChange = (index) => () => {
    if (expanded[index] === true) {
      setExpanded({ [index]: false });
    } else {
      setExpanded({ [index]: true });
    }
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { userData } = useContext(authContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Box
        display="flex"
        sx={{ my: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // marginLeft: 1,
            display: { sm: "none", xs: "none", md: "flex" },
          }}
        >
          <Box component={"img"} maxWidth="150px" alt="Logo" src={logo} />
        </Box>
        <Box
          sx={{
            marginRight: 2,
            mr: 2,
            display: { md: "none", sm: "flex", xs: "flex" },
          }}
        >
          <Box
            component={"img"}
            onClick={handleDrawerToggle}
            maxWidth="150px"
            alt="Logo"
            src={logo}
          />
          {/* <img width="80%" alt="Logo" src={logo} /> */}
        </Box>
      </Box>
      <List>
        {Content.map((data, index) => {
          return (
            <div key={index}>
              {data.items ? (
                <CollapseItem
                  key={index}
                  setMobileOpen={setMobileOpen}
                  expanded={expanded}
                  handleExpandChange={handleExpandChange}
                  data={data}
                  index={index}
                />
              ) : (
                <ListItem
                  key={index}
                  setMobileOpen={setMobileOpen}
                  setExpanded={setExpanded}
                  data={data}
                />
              )}
            </div>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        color="white"
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              // mr: 2,
              display: { md: "none" },
              marginLeft: { md: "0", sm: "10px", xs: "10px" },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Container maxWidth="0">
            <Toolbar disableGutters>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="start"
                  display="flex"
                >
                  <Typography
                    sx={{ fontSize: { md: "20px", sm: "14px", xs: "14px" } }}
                    textAlign="left"
                  >
                    {userData?.firstName} {userData?.lastName} - <Clock />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  alignItems="center"
                  justifyContent="end"
                  display="flex"
                >
                  <FormControlLabel
                    label="Status"
                    labelPlacement="start"
                    control={<Switch defaultChecked />}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              backgroundColor: "drawerColor.main",
            },
          }}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: "drawerColor.main",
            },
          }}
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default LeftDrawer;
