import React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { NoBackpackSharp } from "@mui/icons-material";
import CallIcon from "@mui/icons-material/Call";

const DialButton = (props) => {
  const onClickNumber = props.onClickNumber;

  const handleClick = (number) => {
    onClickNumber(number);
  };

  const dialContent = [
    [
      { number: "1", alpha: "" },
      { number: "2", alpha: "" },
      { number: "3", alpha: "" },
    ],
    [
      { number: "4", alpha: "" },
      { number: "5", alpha: "" },
      { number: "6", alpha: "" },
    ],
    [
      { number: "7", alpha: "" },
      { number: "8", alpha: "" },
      { number: "9", alpha: "" },
    ],
    [
      { number: "*", alpha: "" },
      { number: "0", alpha: "" },
      { number: "+", alpha: "" },
    ],
  ];

  return (
    <Stack spacing={3}>
      {dialContent.map((item, kk) => (
        <Stack key={kk} direction="row" spacing={0}>
          <Grid container rowSpacing={0}>
            {item.map((row, index) => (
              <Grid item key={index} xs={4} paddingX={2}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ width: "100%", borderRadius: "100%" }}
                  onClick={() => handleClick(row.number)}
                >
                  <Typography variant="h4">{row.number}</Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
      <Stack justifyContent="center" alignItems="center">
        <CallIcon
          fontSize="large"
          sx={{
            background: "primary.main",
            width: "80px",
            height: "80px",
            cursor:
              props.phoneNumber.length > 10 && props.phoneNumber.startsWith("+")
                ? "pointer"
                : "default",
          }}
          color={
            props.phoneNumber.length > 10 && props.phoneNumber.startsWith("+")
              ? "green"
              : "grey"
          }
          onClick={() => {
            if (
              props.phoneNumber.length > 10 &&
              props.phoneNumber.startsWith("+")
            ) {
              props.handleDialing(true, props.phoneNumber);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};

export default DialButton;
