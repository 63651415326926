import * as React from "react";
import { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import {
  useAppForm,
  SHARED_CONTROL_PROPS,
  eventPreventDefault,
} from "../../components/utils/form";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Smartphone from "@mui/icons-material/Smartphone";
import Email from "@mui/icons-material/Email";
import Key from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./style.css";
import useCallCenter from "../../hook/useCallCenter";
import { PhoneMissedOutlined } from "@mui/icons-material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const VALIDATE_FORM_LOGIN_PHONE = {
  phone: {
    presence: true,
    length: {
      minimum: 10,
      maximum: 20,
      message: "must be between 10 and 20 numbers",
    },
  },
  code: {
    presence: true,
  },
};

export default function Login() {
  const navigate = useNavigate();
  const { userStatus, setUserStatus, phoneMode } = useCallCenter();
  const [
    formState,
    ,
    /* setFormState */ onFieldChange,
    fieldGetError,
    fieldHasError,
  ] = useAppForm({
    validationSchema: VALIDATE_FORM_LOGIN_PHONE,
    initialValues: { phone: "", code: "" },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const [showCode, setShowCode] = useState(false);
  const values = formState.values; // Typed alias to formState.values as the "Source of Truth"

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleLogin = async () => {
    // const result = await api?.auth?.post("api/auth", values);
    // if (result !== "Sent Code") {
    //     setError('Please check phone and password');
    //     return; // Unsuccessful login
    // }
    setShowCode(true);
  };

  const handleRouting = (event, router) => {
    try {
      if (router.length > 0) {
        setUserStatus(phoneMode.AWAY);
        navigate("/" + router);
      }
    } catch (err) {
      console.log(err);
    }
    // handleClose(event);
  };

  const handleCloseError = useCallback(() => setError(undefined), []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="full-layout"
    >
      <Card sx={{ minWidth: 275, maxWidth: 375 }}>
        <CardHeader
          title="Welcome to Call Center"
          style={{ textAlign: "center" }}
        />
        <CardContent>
          <TextField
            required
            label="Smart Phone Number"
            name="phone"
            // error={fieldHasError('phone')}
            // helperText={fieldGetError('phone') || ' '}
            onChange={onFieldChange}
            {...SHARED_CONTROL_PROPS}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="upload picture" component="label">
                    <Smartphone />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {showCode && (
            <TextField
              required
              label="Enter your code"
              name="code"
              value={values.code}
              error={fieldHasError("code")}
              helperText={fieldGetError("code") || " "}
              onChange={onFieldChange}
              {...SHARED_CONTROL_PROPS}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton aria-label="upload picture" component="label">
                      <Key />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {error ? (
            <Alert severity="error">
              This is an error alert — check it out!
              {error}
            </Alert>
          ) : null}
          <Grid container justifyContent="center" alignItems="center">
            {!showCode && (
              <Button
                type="button"
                variant="contained"
                disabled={!formState.isValid}
                onClick={(e) => handleRouting(e, "callcenter/dashboard")}
              >
                Log in
              </Button>
            )}
            {showCode && (
              <Button
                type="button"
                variant="contained"
                onClick={(e) => handleRouting(e, "callcenter/dashboard")}
              >
                Enter your Code
              </Button>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
