import { useContext } from "react";
import { CallContext } from "../context/CallContext";

const useCall = () => {
  const {
    isCallingState,
    isIncomingCall,
    call,
    device,
    setIsIncomingCall,
    setIsCallingState,
    setCall,
    setDevice,
    acceptIncomingCall,
    rejectIncomingCall,
    hangupIncomingCall,
    handleDisconnectedIncomingCall,
    setIsCalling,
    isCalling,
    gatewayNumber
  } = useContext(CallContext);
  return {
    isCallingState: isCallingState,
    isIncomingCall: isIncomingCall,
    setIsIncomingCall: setIsIncomingCall,
    setIsCallingState: setIsCallingState,
    call: call,
    setCall: setCall,
    device: device,
    setDevice: setDevice,
    acceptIncomingCall,
    rejectIncomingCall,
    hangupIncomingCall,
    handleDisconnectedIncomingCall,
    setIsCalling,
    isCalling,
    gatewayNumber
  };
};

export default useCall;
