import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Card, CardHeader, CardContent, InputAdornment, IconButton, Typography, Alert, Button, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, green } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import "./style.css";
import useCallCenter from '../../hook/useCallCenter';
import AwayStatusDialog from './dialogs/AwayStatus';
import DialModeDialog from './dialogs/DialMode';
import { PhoneAndroid } from '@mui/icons-material';
import VoiceMail from './subpages/VoiceMail';


export default function Dashboard() {
    const {
        userStatus, setUserStatus, statusList, modeString, phoneMode, relatedStatus
    } = useCallCenter();

    const [age, setAge] = useState('away');
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [dialModeDlg, setDialModeDlg] = useState(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const handleModeClick = (index) => {
        if (relatedStatus[userStatus].includes(index)) {
            setUserStatus(index);
            if (index == phoneMode.DIAL)
                setDialModeDlg(true);
        }
    }

    useEffect(() => {
        if (userStatus == phoneMode.DIAL)
            setDialModeDlg(true);
    }, [userStatus]);

    return (
        < Grid
            container
            direction="column"
            className="full-layout"
        >
            < Grid
                container
                direction="row"
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    {statusList.map((item, index) =>
                        <Stack key={index} spacing={0} onClick={() => handleModeClick(index)} className="mode-pointer">
                            {
                                relatedStatus[userStatus].includes(index) ?
                                    <Avatar sx={{ width: 80, height: 80 }} src={item.image} variant="square" >
                                    </Avatar> : <Avatar sx={{ width: 80, height: 80 }} src={item.image} variant="square" className="mode-inactive">
                                    </Avatar>
                            }
                            <Typography variant="subtitle1" component="h2">
                                {item.label}
                            </Typography>
                        </Stack>
                    )}
                </Stack>

                <Typography variant="h4" component="h2">
                    <KeyboardDoubleArrowLeftIcon sx={{ color: "green" }} />
                    {modeString[userStatus]}
                    <KeyboardDoubleArrowRightIcon sx={{ color: "green" }} />
                </Typography>
            </Grid>
            {userStatus == phoneMode.AWAY && <>
                < Grid
                    container
                    direction="row"
                    sx={{ mt: 2 }}
                >
                    <Select
                        value={age}
                        onChange={handleChange}
                        label=""
                        sx={{ minWidth: 150, mr: 3, maxHeight: 40 }}
                    >
                        <MenuItem value={"away"}>Away</MenuItem>
                    </Select>
                    <Button variant="contained" onClick={() => setStatusDialogOpen(true)}>
                        Edit
                    </Button>
                </Grid>
                <AwayStatusDialog open={statusDialogOpen} setOpen={setStatusDialogOpen} />
            </>
            }
            {userStatus == phoneMode.DIAL && <DialModeDialog open={dialModeDlg} setOpen={setDialModeDlg} />}
            {userStatus == phoneMode.VOICEMAIL &&
                <Stack sx={{ width: "100%", mt: 1, maxHeight: "75%" }}>
                    <VoiceMail />
                </Stack>}

        </Grid>
    );
}