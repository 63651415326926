import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Device } from "@twilio/voice-sdk";
import DialButton from "./DialButton";
import CallingPad from "./CallingPad";
import CallInput from "./CallInput";
import CallFrom from "./CallFrom";
import useCall from "../../../hook/useCall";
import PropTypes from "prop-types";
import IncomingCallModal from "./IncomingCallModal";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Contact from "./Contact";
import CallHistory from "./CallHistory";
import useCallApi from "../../../api/useCallApi";
import useAuth from "../../../hook/useAuth";
import { SocketContext } from "../../../context/SocketContext";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DialPad = (props) => {
  const { callSessionId, resetCallSessionId, setCallSessionId } = useAuth();
  const { leadData, setLeadData } = useContext(SocketContext);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  console.log("callSessionIdcallSessionId", callSessionId);
  // const [incomingCallIndex, setIncomingCallIndex] = useState(0);
  // const [timestamp, setTimestamp] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const [requestedCallIdId, setRequestedCallIdId] = useState("");

  const {
    isCallingState,
    setIsCallingState,
    setIsIncomingCall,
    isIncomingCall,
    call,
    setCall,
    device,
    setDevice,
    acceptIncomingCall,
    rejectIncomingCall,
    isCalling,
    setIsCalling,
  } = useCall();

  const [twilioToken, setTwilioToken] = useState("");
  const [deviceInputAvailable, setDeviceInputAvailable] = useState([]);
  const [defaultDeviceInput, setDefaultDeviceInput] = useState("");
  const [deviceOutputAvailable, setDeviceOutputAvailable] = useState([]);
  const [defaultDeviceOutput, setDefaultDeviceOutput] = useState("");
  const {
    getCallHistoryApi,
    getContactApi,
    getTwilioToken,
    makeCallApi,
    checkCallApi,
    addPersonInConferenceApi,
    getSingleHistoryFromCallId,
  } = useCallApi();

  const getToken = async () => {
    const response = await getTwilioToken();

    setTwilioToken(response.token);
  };
  useEffect(() => {
    getToken();
  }, []);

  const [history, setHistory] = useState([]);
  const [historyPage, setHistoryPage] = useState(1);
  const [contactPage, setContactPage] = useState(1);

  const [totalHistory, setTotalHistory] = useState(0);
  const [totalContact, setTotalContact] = useState(0);
  const getHistory = async () => {
    const response = await getCallHistoryApi(historyPage);
    if (response) {
      setHistory(response.data);
      setTotalHistory(response.total);
    }
  };
  useEffect(() => {
    getHistory();
  }, [historyPage]);

  useEffect(() => {
    if (twilioToken === null) return;
    props.setDialNumber(props.dialNumber);
    async function getFetch() {
      await startUpClient();
    }
    getFetch();
  }, [twilioToken]);

  useEffect(() => {
    if (device) {
      try {
        addDeviceListeners(callSessionId);
        device.register();
      } catch (err) {
        console.log(err);
      }

      return () => {
        addDeviceListeners(callSessionId);
      };
    }
  }, [device]);

  const startUpClient = async () => {
    try {
      initializeDevice(twilioToken);
    } catch (error) {
      console.log("An error occurred. in start up client ", error);
    }
  };

  const initializeDevice = (accessToken) => {
    setDevice(
      new Device(accessToken, {
        logLevel: 1,
        codecPreferences: ["opus", "pcmu"],
      })
    );
  };

  const addDeviceListeners = (seId) => {
    console.log("callSessionIdcallSessionId============>", callSessionId, seId);
    device.on("registered", function () {
      console.log("Twilio.Device Ready to make and receive calls!");
    });
    device.on("tokenWillExpire", async () => {
      const response = await getTwilioToken();
      device.updateToken(response.token);
    });
    device.on("error", function (error) {
      console.log("Twilio.Device Error: " + error.message);
    });

    device.on("incoming", (callData) => handleIncomingCall(callData, seId));
    device.audio.on("deviceChange", () => {
      updateAllAudioDevices.bind(device);
      const deviceOutputAvailableArray = [];
      device.audio.availableOutputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceOutputAvailableArray.push({ label: device.label, id: id });
      });
      if (deviceOutputAvailableArray.length !== 0) {
        setDefaultDeviceOutput(deviceOutputAvailableArray[0].id);
      }
      setDeviceOutputAvailable(deviceOutputAvailableArray);
      const deviceInputAvailableArray = [];
      device.audio.availableInputDevices.forEach((device, id) => {
        var deviceOption = document.createElement("option");
        deviceOption.label = device.label;
        deviceOption.setAttribute("data-id", id);
        deviceInputAvailableArray.push({ label: device.label, id: id });
      });
      setDeviceInputAvailable(deviceInputAvailableArray);
      if (deviceInputAvailableArray.length !== 0) {
        setDefaultDeviceInput(deviceInputAvailableArray[0].id);
      }
    });
  };

  const handleIncomingCall = async (callData, sessionIdData) => {
    await delay(2000);
    const res = await checkCallApi(sessionIdData);
    console.log("callSessionId", sessionIdData);

    if (callData.parameters.CallSid === res?.data.callId) {
      acceptIncomingCall(callData);
      setCall(callData);
      // setTimestamp(0);
      incomingCallEvents(callData);
    } else {
      setCall(callData);
      setIsIncomingCall(true);
      // setTimestamp(0);
      incomingCallEvents(callData);
    }
  };

  const acceptIncomingCallHandler = async (callData) => {
    setIsIncomingCall(false);
    console.log("callData", callData);

    const data = await getSingleHistoryFromCallId(callData.parameters.CallSid);
    setCallSessionId(data.sessionId);

    setIsCallingState(true);
    setIsCalling(true);
    acceptIncomingCall(callData);
    // setCall(callData);
  };
  const rejectIncomingCallHandler = (callData) => {
    console.log("callData", callData);
    setLeadData();
    addDeviceListeners(callSessionId);
    setIsIncomingCall(false);
    setIsCalling(false);
    setIsCallingState(false);
    resetCallSessionId();
    rejectIncomingCall(callData);
    setCall(callData);
  };
  const updateAllAudioDevices = () => {
    if (device) {
      updateDevices("speakerDevices", device.audio.speakerDevices.get());
      updateDevices("ringtoneDevices", device.audio.ringtoneDevices.get());
    }
  };
  if (device) {
    for (
      let index = 0;
      index < device.audio.speakerDevices.get().length;
      index++
    ) {
      const element = device.audio.speakerDevices.get()[index];
    }
  }

  const updateDevices = (selectEl, selectedDevices) => {
    device.audio.availableOutputDevices.forEach(function (device, id) {
      var isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(function (device) {
        if (device.deviceId === id) {
          isActive = true;
        }
      });
    });
  };

  const delay = (delayInms) => {
    return new Promise((resolve) => setTimeout(resolve, delayInms));
  };

  const [contacts, setContacts] = useState([]);
  const getContact = async () => {
    const response = await getContactApi(contactPage);

    if (response) {
      setContacts(response.data);
      setTotalContact(response.total);
    }
  };
  useEffect(() => {
    getContact();
  }, [contactPage]);

  const makeOutgoingCall = async (number) => {
    var params = {
      To: number,
      From: "+12024172529",
      session_id: callSessionId,
    };

    if (device) {
      const data = await makeCallApi(params);
      // setRequestedCallIdId(data);
      setIsCalling(true);
      setIsCallingState(true);
    } else {
      setIsCalling(false);
      setIsCallingState(false);
      resetCallSessionId();
    }
  };

  const incomingCallEvents = (call) => {
    call.on("accept", async (remoteStream) => {});
    call.on("disconnect", async () => {
      await getHistory(historyPage);
      setIsIncomingCall(false);
      // setIncomingCallIndex(0);
      // setTimestamp(0);
      resetCallSessionId();
      setIsCalling(false);
      setIsCallingState(false);
    });
    call.on("cancel", () => {
      setIsIncomingCall(false);
    });
  };

  const onClickNumber = (number) => {
    props.setDialNumber(props.dialNumber + number);
  };

  const handlePhoneNumber = (number) => {
    props.setDialNumber(number);
  };

  const handleDialing = async (setCalling, number) => {
    makeOutgoingCall(number ? number : props.dialNumber);
    props.setDialNumber("");
  };

  const handleEndCall = async () => {
    try {
      setLeadData();
      call.disconnect();
      setIsCalling(false);
      setIsCallingState(false);
      await getHistory(historyPage);
      const seId = resetCallSessionId();
      console.log("end call=====", seId);
      addDeviceListeners(seId);
    } catch (e) {}
  };

  function changeInputDevice(deviceId) {
    const constraints = { audio: { deviceId: { exact: deviceId } } };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {})
      .catch((error) => {
        console.error("Error accessing new microphone:", error);
      });
  }

  return (
    <>
      <IncomingCallModal
        open={isIncomingCall}
        data={call}
        // setTimestamp={setTimestamp}
        // timestamp={timestamp}
        // index={incomingCallIndex}
        // setIndex={setIncomingCallIndex}
        // setIsIncomingCall={setIsIncomingCall}
        leadData={leadData}
        acceptIncomingCall={acceptIncomingCallHandler}
        rejectIncomingCall={rejectIncomingCallHandler}
        // hangupIncomingCall={hangupIncomingCall}
        // handleDisconnectedIncomingCall={handleDisconnectedIncomingCall}
      />
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          marginBottom="25px"
          paddingY="25px"
          paddingX="25px"
          component={Paper}
        >
          <Grid item xs={6} sx={{ paddingRight: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="select-speaker-devices-label">
                Select speaker devices
              </InputLabel>
              <Select
                labelId="select-speaker-devices-label"
                value={defaultDeviceOutput}
                label="Select speaker devices"
                name="speaker"
                onChange={async (q) => {
                  setDefaultDeviceOutput(q.target.value);
                }}
              >
                {deviceOutputAvailable.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="select-microphone-devices-label">
                Select microphone devices
              </InputLabel>
              <Select
                labelId="select-microphone-devices-label"
                value={defaultDeviceInput}
                name="microphone"
                onChange={async (q) => {
                  await device.audio.setInputDevice(q.target.value);
                  setDefaultDeviceInput(q.target.value);
                  changeInputDevice(q.target.value);
                }}
                label="Select microphone devices"
              >
                {deviceInputAvailable.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!matches ? (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Caller" {...a11yProps(0)} />
                <Tab label="History" {...a11yProps(1)} />
                <Tab label="Contact" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Stack justifyContent="center" alignItems="center" spacing={5}>
                <CallFrom />
                {!isCalling ? (
                  <>
                    <CallInput
                      handlePhoneNumber={handlePhoneNumber}
                      phoneNumber={props.dialNumber}
                      handleDialing={handleDialing}
                    />
                    <DialButton
                      onClickNumber={onClickNumber}
                      handlePhoneNumber={handlePhoneNumber}
                      phoneNumber={props.dialNumber}
                      handleDialing={handleDialing}
                    />
                  </>
                ) : (
                  <CallingPad
                    id="audioOutput"
                    isCallStarted={isCallingState}
                    addPersonInConferenceApi={addPersonInConferenceApi}
                    sessionId={callSessionId}
                    endCall={handleEndCall}
                    user={props.user}
                    call={call}
                    makeCall={makeOutgoingCall}
                    timestamp={new Date().getTime()}
                  />
                )}
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CallHistory
                setPage={setHistoryPage}
                page={historyPage}
                history={history}
                setDialNumber={props.setDialNumber}
                handleDialing={handleDialing}
                setValue={setValue}
                total={totalHistory}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Contact
                total={totalContact}
                setPage={setContactPage}
                page={contactPage}
                getContact={getContact}
                contacts={contacts}
                setDialNumber={props.setDialNumber}
                handleDialing={handleDialing}
                setValue={setValue}
              />
            </CustomTabPanel>
          </>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              lg={4}
              alignItems="start"
              flexDirection="column"
              display="flex"
              alignSelf="stretch"
            >
              <Paper
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "#eee",
                  }}
                >
                  <Typography
                    variant="h5"
                    textAlign="left"
                    sx={{ padding: "10px 15px" }}
                  >
                    Call as
                  </Typography>
                </Box>
                {!isCalling ? (
                  <>
                    <CallInput
                      handlePhoneNumber={handlePhoneNumber}
                      phoneNumber={props.dialNumber}
                      handleDialing={handleDialing}
                    />
                    <DialButton
                      onClickNumber={onClickNumber}
                      handlePhoneNumber={handlePhoneNumber}
                      phoneNumber={props.dialNumber}
                      handleDialing={handleDialing}
                    />
                  </>
                ) : (
                  <CallingPad
                    addPersonInConferenceApi={addPersonInConferenceApi}
                    isCallStarted={isCallingState}
                    endCall={handleEndCall}
                    sessionId={callSessionId}
                    user={props.user}
                    call={call}
                    makeCall={makeOutgoingCall}
                    timestamp={new Date().getTime()}
                  />
                )}
              </Paper>
            </Grid>
            <Grid
              item
              lg={4}
              alignItems="start"
              flexDirection="column"
              display="flex"
              alignSelf="stretch"
            >
              <CallHistory
                setPage={setHistoryPage}
                page={historyPage}
                history={history}
                setDialNumber={props.setDialNumber}
                handleDialing={handleDialing}
                setValue={setValue}
                total={totalHistory}
                setHistory={setHistory}
                getHistory={getHistory}
              />
            </Grid>
            <Grid
              item
              lg={4}
              alignItems="start"
              flexDirection="column"
              display="flex"
              alignSelf="stretch"
            >
              <Contact
                total={totalContact}
                setPage={setContactPage}
                page={contactPage}
                getContact={getContact}
                contacts={contacts}
                setDialNumber={props.setDialNumber}
                handleDialing={handleDialing}
                setValue={setValue}
                setContacts={setContacts}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default DialPad;
