import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { MenuBar } from "../MenuBar/";
import Clock from "clock-react";
import SearchForm from "./searchForm";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./NavBar.css";

const style = {
  color: "black",
  // fontFamily: 'italic'
};

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function Navbar() {
  return (
    // <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <AppBar position="static" style={{ background: "#ffffff" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container spacing={0}>
            <Grid item sx={{ width: "200px" }}>
              {/* <img alt="Digital Company" src={logo} height="100px" /> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={1}>
                <Grid item>
                  <Box
                    display="flex"
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "20px",
                        fontFamily: "inherit",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      <Box display="flex" sx={{ color: "black" }}>
                        {"John Doe"} &nbsp;&nbsp;- &nbsp;&nbsp;
                        {new Date().toDateString()}&nbsp;&nbsp;- &nbsp;&nbsp;
                        <Clock style={style} />
                      </Box>
                    </div>
                    <Box display="flex">
                      <SearchForm />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    sx={{
                      y: 0,
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                    }}
                    style={{ flexDirection: "row" }}
                  >
                    <Link to="/inbox">
                      <Typography variant="subtitle1" component="h2">
                        Unanswered emails (1)
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    display="flex"
                    sx={{
                      y: 0,
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                    }}
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link to="/">
                      <Typography variant="subtitle1" component="h2">
                        Unanswered voice mails (2)
                      </Typography>
                    </Link>
                    <Typography
                      variant="subtitle1"
                      component="h2"
                      sx={{ color: "black" }}
                    >
                      Clock In
                    </Typography>

                    <Link to="/inbox">
                      <Typography variant="subtitle1" component="h2">
                        some text here
                      </Typography>
                    </Link>

                    <FormControlLabel
                      control={<Android12Switch defaultChecked />}
                      label="User Status"
                      labelPlacement="start"
                      sx={{ color: "black", fontWeight: "bold" }}
                    />
                    <Link to="/inbox">
                      <Typography variant="subtitle1" component="h2">
                        some text here
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
