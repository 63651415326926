import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from "react";


export const DCContext = React.createContext({});

export const DCContextProvider = ({
  children,
}) => {
  const [userType, setUserType] = useState();
  const [user, setUser] = useState();
  return (
    <DCContext.Provider
      value={{
        user,
        setUser,
        userType,
        setUserType
      }}
    >
      {children}
    </DCContext.Provider>
  );
};