import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CallIcon from "@mui/icons-material/Call";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Counter from "./CountDown";
import avatar_image from "../../../assets/img/call_avatar.png";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CallInput from "./CallInput";
import DialButton from "./DialButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CallingPad = (props) => {
  const [displayDial, setDisplayDial] = useState(false);
  const [topMarketerNumber, setTopMarketerNumber] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  // const [state, dispatch] = useAppStore();
  const [number, setNumber] = useState("");

  const handleClose = () => {
    setDisplayDial(false);
  };

  const handleAdd = async (a, numb) => {
    setDisplayDial(false);
    props.addPersonInConferenceApi(props.sessionId, numb);
  };

  const onClickNumber = (num) => {
    setNumber(number + num);
  };
  const handlePhoneNumber = (number) => {
    // console.log(number);
    setNumber(number);
  };
  return (
    <Stack
      spacing={3}
      style={{ width: "80%", height: "100%" }}
      justifyContent="center"
    >
      <Stack direction="row" spacing={2} justifyContent="center">
        {isAdded && (
          <Stack>
            <Stack spacing={3} justifyContent="center" direction="row">
              <Avatar src={avatar_image} sx={{ width: 84, height: 84 }} />
            </Stack>
            <Stack spacing={3} justifyContent="center" direction="row">
              <Typography variant="h6">{topMarketerNumber.name}</Typography>
            </Stack>
          </Stack>
        )}
        <Stack>
          <Stack spacing={3} justifyContent="center" direction="row">
            <Avatar src={avatar_image} sx={{ width: 84, height: 84 }} />
          </Stack>
          <Stack spacing={3} justifyContent="center" direction="row">
            <Typography variant="h6">{props.user}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {!props.isCallStarted && (
        <Stack spacing={3} justifyContent="center" direction="row">
          <Typography variant="h6">Calling...</Typography>
        </Stack>
      )}
      {props.isCallStarted && (
        <Stack spacing={3} justifyContent="center" direction="row">
          <Typography variant="h6">
            <Counter timestamp={props.timestamp} />
          </Typography>
        </Stack>
      )}

      <Stack
        spacing={3}
        justifyContent="center"
        direction="row"
        style={{ marginBottom: "20px" }}
      >
        {props.isCallStarted && (
          <>
            <Fab
              aria-label="call-end"
              color="default"
              onClick={() => setDisplayDial(!displayDial)}
            >
              <AddIcon sx={{ mr: 2, ml: 2 }} />
            </Fab>
          </>
        )}
        <Fab
          aria-label="call-end"
          color="error"
          onClick={() => props.endCall()}
        >
          <CallIcon sx={{ mr: 2, ml: 2 }} />
        </Fab>
      </Stack>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={displayDial}
        sx={{ minWidth: 500 }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ minWidth: 500 }}
        >
          Add a new participant
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="column">
            <CallInput
              handlePhoneNumber={handlePhoneNumber}
              phoneNumber={number}
              handleDialing={handleAdd}
            />
            <DialButton
              onClickNumber={onClickNumber}
              handlePhoneNumber={handlePhoneNumber}
              phoneNumber={number}
              handleDialing={handleAdd}
            />
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </Stack>
  );
};

export default CallingPad;
