import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hook/useAuth";
const Logout = () => {
  const navigation = useNavigate();
  const { setLoggedIn, setIsLoading, setUserData } = useAuth();
  useEffect(() => {
    setIsLoading(true);
    setUserData();
    setLoggedIn(false);
    setIsLoading(false);

    localStorage.removeItem("bearer");

    navigation("/login");
  }, []);

  return <div>Logout</div>;
};

export default Logout;
