import React from "react";
import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import CallIcon from "@mui/icons-material/Call";
import Fab from "@mui/material/Fab";
import DirectionsIcon from "@mui/icons-material/Directions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useAppStore } from "../../../store/AppStore";

const CallFrom = (props) => {
  // const [state, dispatch] = useAppStore();

  return (
    <Stack>
      <Typography variant="h5">Call as</Typography>
      <Typography variant="button">
        {/* {state.currentUser?.phone} */}
      </Typography>
    </Stack>
  );
};

export default CallFrom;
