import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./style.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2196f3",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(desc, dnis, ani, leave_data, message) {
  return { desc, dnis, ani, leave_data, message };
}

function CustomizedTables(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow hover>
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell align="center">DNIS</StyledTableCell>
            <StyledTableCell align="center">ANI</StyledTableCell>
            <StyledTableCell align="center">Leave Data</StyledTableCell>
            <StyledTableCell align="center">Message</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.length == 0 ? (
            <StyledTableRow>
              <StyledTableCell
                component="th"
                style={{ width: 40 }}
                scope="row"
                colSpan={6}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.warn}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            props.rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  component="th"
                  style={{ width: 40 }}
                  scope="row"
                >
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.desc}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  <a href="#">{row.dnis ? "Play" : ""}</a>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  <a href="#">{row.ani ? "Record" : ""}</a>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  <a href="#">{row.leave_data ? "Delete" : ""}</a>
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: 80 }}>
                  <a href="#">{row.message ? "Delete" : ""}</a>
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function VoiceMail() {
  const [currentStatus, setCurrentStatus] = useState(0);
  const [statusList, setStatusList] = useState([]);

  return (
    <Box sx={{ overflow: "auto", mt: 3 }}>
      <Typography variant="h5" component="h2" color="red">
        New messages
      </Typography>
      <Box sx={{ overflow: "auto", mt: 3, maxHeight: "30%" }}>
        <CustomizedTables
          index={currentStatus}
          setIndex={setCurrentStatus}
          rows={statusList}
          warn={"You have no messages"}
        />
      </Box>
      <Typography variant="h5" component="h2" color="red" sx={{ mt: 5 }}>
        Saved messages
      </Typography>
      <Box sx={{ overflow: "auto", mt: 3, maxHeight: "30%" }}>
        <CustomizedTables
          index={currentStatus}
          setIndex={setCurrentStatus}
          rows={statusList}
          warn={"You have no saved messages"}
        />
      </Box>
    </Box>
  );
}
