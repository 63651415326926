import {
  Avatar,
  Box,
  Button,
  Divider,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";

import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import useCallApi from "./../../../api/useCallApi";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect } from "react";
const Contact = ({
  setDialNumber,
  handleDialing,
  setValue,
  getContact,
  contacts,
  total,
  setPage,
  page,
  setContacts,
}) => {
  const [data, setData] = useState({ name: "", phone: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { createContactApi, searchContactApi } = useCallApi();
  const [searchTestString, setSearchTestString] = useState("");

  const createContactHandler = async (e) => {
    e.preventDefault();
    if (await createContactApi(data)) {
      getContact(page);
      setData({ name: "", phone: "" });
      handleClose();
    }
  };
  const searchContactHandler = async (e) => {
    setSearchTestString(e.target.value);
    const response = await searchContactApi(e.target.value);
    setContacts(response.data);
  };
  const onChange = (event) => {
    if (event.target.name === "phone") {
      const value =
        event.target.value.length < 15
          ? { [event.target.name]: event.target.value.replace(/[^0-9+]/g, "") }
          : { [event.target.name]: data[event.target.name] };
      setData({
        ...data,
        ...value,
      });
    } else {
      const value2 =
        event.target.value.length < 30
          ? { [event.target.name]: event.target.value }
          : { [event.target.name]: data[event.target.name] };
      setData({ ...data, ...value2 });
    }
  };
  const searchCloseHandler = async (event) => {
    setSearchTestString("");
    setPage(1);
    getContact(page);
  };
  return (
    <Paper
      sx={{
        width: "100%",
        // padding: "20px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        paddingBottom: "65px",
      }}
    >
      <Box
        sx={{
          // display: "block",
          width: "100%",

          bgcolor: "#eee",
        }}
      >
        <Typography variant="h5" textAlign="left" sx={{ padding: "10px 15px" }}>
          Contact
        </Typography>
      </Box>
      <TextField
        label="Search Contacts"
        size="medium"
        name="email"
        sx={{ width: "90%", margin: "20px" }}
        value={searchTestString}
        inputProps={{ maxLength: 64 }}
        onChange={(e) => searchContactHandler(e)}
        InputProps={{
          sx: { borderRadius: "300px" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="upload picture" component="label">
                {searchTestString && searchTestString.length !== 0 ? (
                  <CloseIcon onClick={searchCloseHandler} />
                ) : (
                  <SearchIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <>
        <List dense sx={{ width: "100%" }}>
          {contacts.map((item, index) => {
            const labelId = `checkbox-list-secondary-label-${item._id}`;
            return (
              <div key={index}>
                <ListItem
                  sx={{ cursor: "pointer" }}
                  secondaryAction={
                    <>
                      <CallIcon
                        onClick={() => {
                          setDialNumber(item.phone);
                          handleDialing(true, item.phone);
                          setValue(0);
                        }}
                      />
                    </>
                  }
                >
                  <ListItemButton>
                    <ListItemAvatar>
                      <Avatar
                        onClick={() => {
                          console.log(item);
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={
                        <Box>
                          {item.name ? (
                            <>
                              <Typography>{item.name}</Typography>{" "}
                              <Typography fontSize={10}>
                                {item.phone}
                              </Typography>
                            </>
                          ) : (
                            <Typography>{item.phone}</Typography>
                          )}
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider variant="middle" />
              </div>
            );
          })}
        </List>
        <Fab
          color="primary"
          aria-label="add"
          sx={{ position: "absolute", bottom: "40px", right: "40px" }}
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Create Contact Form
            </Typography>
            <Stack
              spacing={2}
              component={"form"}
              onSubmit={createContactHandler}
            >
              <TextField
                id="outlined-basic"
                label="Name "
                name="name"
                required
                value={data.name}
                variant="outlined"
                onChange={onChange}
              />
              <TextField
                id="outlined-basic"
                label="Phone Number"
                name="phone"
                value={data.phone}
                required
                variant="outlined"
                onChange={onChange}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={
                  !(
                    data.phone.length > 10 &&
                    data.phone.startsWith("+") &&
                    data.name.length > 3
                  )
                }
              >
                Create
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
      <Pagination
        count={Math.trunc(total / 10) + 1}
        page={page}
        sx={{ position: "absolute", bottom: "20px" }}
        onChange={(a, pageNo) => {
          setPage(pageNo);
          // console.log(pageNo);
        }}
      />
    </Paper>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default Contact;
