import React from "react";

import IconButton from "@mui/material/IconButton";

import { InputAdornment, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const CallInput = (props) => {
  return (
    <TextField
      label="Enter number with country code"
      name="phone"
      size="medium"
      inputProps={{
        "aria-label": "enter number with country code",
        // pattern: "[a-z]",
      }}
      value={props.phoneNumber}
      onChange={(e) => {
        props.handlePhoneNumber(e.target.value.replace(/[^0-9+]/g, ""));
      }}
      sx={{ width: "90%", margin: "20px" }}
      InputProps={{
        sx: { borderRadius: "300px" },
        endAdornment: (
          <InputAdornment position="end">
            {props.phoneNumber ? (
              <IconButton
                aria-label="upload picture"
                component="label"
                onClick={() => {
                  props.handlePhoneNumber("");
                }}
              >
                {props.phoneNumber ? <CloseIcon /> : <></>}
              </IconButton>
            ) : (
              <></>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CallInput;
