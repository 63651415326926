import React, { useState } from "react";
import Box from "@mui/material/Box";
import DialPad from "./DialPad";

const Call = () => {
  const [number, setNumber] = useState("");
  // const [reload, setReload] = useState(false);

  const handleNumber = (num) => {
    setNumber(num);
  };

  return (
    <Box
      xs={3}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DialPad
        // triggerReload={setReload}
        dialNumber={number}
        setDialNumber={handleNumber}
        user={number}
      />
    </Box>
  );
};
export default Call;
