import React from "react";
// import { baseUrl, dashboard, login } from "./useApiEndPoint";
import axios from "axios";
import useAuth from "../hook/useAuth";
// import { useNavigate } from "react-router-dom";
import useApiEndPoint from "./useApiEndPoint";

const useAuthApi = () => {
  const { baseUrl, dashboardEndPoint, loginEndPoint, signupEndPoint } =
    useApiEndPoint();

  const {
    loggedIn,
    setLoggedIn,
    setIsLoading,
    userData,
    setUserData,
    snackbar,
    homeLoader,
    setHomeLoader,
  } = useAuth();

  const login = async ({ email, password }) => {
    try {
      setIsLoading(true);
      if (!email || !password) {
        setIsLoading(false);
        return snackbar("error", "Please enter your email");
      }
      const response = await axios.post(`${baseUrl}${loginEndPoint}`, {
        email: email.toLowerCase(),
        password,
      });
      setIsLoading(false);
      setUserData(response.data.user);
      setLoggedIn(true);
      localStorage.setItem("bearer", response.data.token);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      snackbar("error", error.response.data.message);
      return null;
    }
  };
  const signup = async ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    phone,
  }) => {
    try {
      setIsLoading(true);
      if (!email || !password) {
        setIsLoading(false);
        return snackbar("error", "Please enter your email");
      }
      const response = await axios.post(`${baseUrl}${signupEndPoint}`, {
        email: email.toLowerCase(),
        password,
        firstName,
        lastName,
        confirmPassword,
        phone,
      });

      setIsLoading(false);
      snackbar("success", response.data.message);

      return true;
    } catch (error) {
      setIsLoading(false);
      snackbar("error", error.response.data.message);
    }
  };
  const dashboard = async () => {
    try {
      setHomeLoader(true);
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}${dashboardEndPoint}`, {
        headers: { bearer: localStorage.getItem("bearer") },
      });
      setLoggedIn(true);
      setIsLoading(false);
      setUserData(response.data.data);
      setHomeLoader(false);

      return response.data;
    } catch (error) {
      setLoggedIn(false);
      setIsLoading(false);
      setUserData({});
      setHomeLoader(false);
    }
  };
  return { login, signup, dashboard };
};

export default useAuthApi;
