import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from "react";

export const CallContext = React.createContext({
  isCallingState: false,
  setIsCallingState: () => {},
});

export const CallContextProvider = ({ children }) => {
  const [isCallingState, setIsCallingState] = useState(false);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [call, setCall] = useState();
  const [device, setDevice] = useState();
  const [gatewayNumber, setGatewayNumber] = useState("+12024172529");

  function acceptIncomingCall(call) {
    call.accept();
    //update UI
    console.log("Accepted incoming call.");
  }

  // REJECT INCOMING CALL

  function rejectIncomingCall(call) {
    call.reject();
    console.log("Rejected incoming call");
  }

  // HANG UP INCOMING CALL

  function hangupIncomingCall(call) {
    call.disconnect();
    console.log("Hanging up incoming call");
  }

  // HANDLE CANCELLED INCOMING CALL

  function handleDisconnectedIncomingCall() {
    console.log("Incoming call ended.");
  }

  return (
    <CallContext.Provider
      value={{
        isCallingState,
        isIncomingCall,
        call,
        device,
        isCalling,
        gatewayNumber,
        setIsIncomingCall,
        setIsCallingState,
        setCall,
        setDevice,
        acceptIncomingCall,
        rejectIncomingCall,
        hangupIncomingCall,
        handleDisconnectedIncomingCall,
        setIsCalling,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
