import React, { useState, useEffect } from "react";
import callDetail from "../assets/img/call details.png";
import phoneAway from "../assets/img/phone away.png";
import phoneDial from "../assets/img/phone dial.png";
import hungUp from "../assets/img/phone hung up.png";
import vMailBox from "../assets/img/voice mailbox.png";
import phoneTransfer from "../assets/img/phone transfer.png";
import broadcast from "../assets/img/phone boradcast.png";
import phoneReady from "../assets/img/phone ready1.png";
import { PhoneEnabled } from "@mui/icons-material";

export const callCenterContext = React.createContext();

const statusList = [
  { image: hungUp, label: "Hang Up" },
  { image: phoneReady, label: "Ready" },
  { image: phoneTransfer, label: "Transfer" },
  { image: phoneDial, label: "Dial" },
  { image: phoneAway, label: "Away" },
  { image: vMailBox, label: "V.Mailbox" },
  { image: callDetail, label: "Call Details" },
  { image: broadcast, label: "Broadcast" },
];

const modeString = [
  "Hang Up",
  "Ready",
  "Transfer",
  "Dial",
  "Away",
  "V.Mailbox",
  "Call Details",
  "Broadcast",
];

const phoneMode = {
  HANG_UP: 0,
  READY: 1,
  TRANSFER: 2,
  DIAL: 3,
  AWAY: 4,
  VOICEMAIL: 5,
  CALL_DETAIL: 6,
  BROADCAST: 7,
};

const relatedStatus = [
  [],
  [phoneMode.AWAY],
  [phoneMode.HANG_UP, phoneMode.TRANSFER, phoneMode.DIAL, phoneMode.AWAY],
  [phoneMode.READY, phoneMode.VOICEMAIL, phoneMode.DIAL],
  [
    phoneMode.READY,
    phoneMode.DIAL,
    phoneMode.VOICEMAIL,
    phoneMode.CALL_DETAIL,
    phoneMode.BROADCAST,
  ],
  [phoneMode.READY],
  [phoneMode.READY],
  [phoneMode.READY],
];

export const CallCenterContextProvider = ({ children }) => {
  // const [curSocket, setCurSocket] = useState<Socket>({} as Socket);

  const [userStatus, setUserStatus] = useState(phoneMode.AWAY);

  return (
    <callCenterContext.Provider
      value={{
        relatedStatus,
        modeString,
        statusList,
        userStatus,
        phoneMode,
        setUserStatus,
      }}
    >
      {children}
    </callCenterContext.Provider>
  );
};
