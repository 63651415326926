import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";

const useContent = () => {
  // const { adminData } = useContext(AdminDataContext);

  const content = [
    {
      icon: DashboardIcon,
      text: "Dashboard",
      slug: "",
    },
    {
      icon: DashboardIcon,
      text: "Account",
      slug: "account",
    },
    {
      icon: DashboardIcon,
      text: "Call Center",
      slug: "callcenter",
    },
    {
      icon: DashboardIcon,
      text: "Email Parser",
      slug: "mailparser",
    },
  ];

  content.push({
    icon: LogoutIcon,
    text: "Logout",
    slug: "logout",
  });
  return content;
};
export default useContent;
