import * as React from "react";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import classes from "./Styles.module.css";
import useAuthApi from "../../api/useAuthApi";
import LoginBg from "./../../assets/img/loginBg.jpg";

export default function Login() {
  document.title = "User Control Panel - Login";
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const navigate = useNavigate();
  const { login } = useAuthApi();
  const submitLoginFormApi = async (data) => {
    const response = login(data);
    if (response) {
      navigate("/");
    }
  };

  return (
    <div className="d-lg-flex half">
      <div
        className="bg order-1 order-md-2"
        style={{
          backgroundImage: `url(${LoginBg})`,
        }}
      />
      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <h3 className="mb-4">
                Login to <strong>User Control Panel</strong>
              </h3>
              <form
                onSubmit={handleSubmit((data) => {
                  submitLoginFormApi(data);
                })}
              >
                <div className="form-group first">
                  <label htmlFor="email">Email</label>
                  <input
                    {...register("email", {
                      required: "Email is required.",
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Enter a valid email address",
                      },
                      maxLength: {
                        value: 64,
                        message: "Enter a valid email address",
                      },
                      minLength: {
                        value: 5,
                        message: "Enter a valid email address",
                      },
                    })}
                    type="text"
                    maxLength={64}
                    className="form-control  is-invalid"
                    placeholder="your-email@gmail.com"
                    id="email"
                  />
                  <div className="invalid-feedback">
                    {errors?.email?.message}
                  </div>
                </div>
                <div className="form-group last mb-5">
                  <label htmlFor="password">Password</label>
                  <input
                    {...register("password", {
                      required: "Password is required.",

                      maxLength: {
                        value: 16,
                        message: "Max length is 16 characters",
                      },
                      minLength: {
                        value: 8,
                        message: "Min length is 8 characters",
                      },
                    })}
                    maxLength={16}
                    minLength={8}
                    type="password"
                    className="form-control is-invalid"
                    placeholder="Your Password"
                    id="password"
                  />
                  <div className="invalid-feedback">
                    {errors?.password?.message}
                  </div>
                </div>
                <input
                  type="submit"
                  defaultValue="Log In"
                  className="btn btn-block btn-primary"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
