import React, { useState, useEffect } from "react";

export const authContext = React.createContext();

export const AuthContextProvider = ({ children }) => {
  // const [curSocket, setCurSocket] = useState<Socket>({} as Socket);

  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [homeLoader, setHomeLoader] = useState(true);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [callSessionId, setCallSessionId] = useState(
    `${Math.trunc(Math.random() * 9999999)}${new Date().getTime()}`
  );

  const snackbar = (severityData, messageData) => {
    setOpenSnackbar(true);
    setSeverity(severityData);
    setMessage(messageData);
  };
  const resetCallSessionId = () => {
    const newSessionId = `${Math.trunc(
      Math.random() * 9999999
    )}${new Date().getTime()}`;
    console.log(
      "resetCallSessionIdresetCallSessionIdresetCallSessionIdresetCallSessionIdresetCallSessionIdresetCallSessionIdresetCallSessionId",
      newSessionId
    );
    setCallSessionId((prev) => newSessionId);
    return newSessionId;
  };

  return (
    <authContext.Provider
      value={{
        isLoading,
        loggedIn,
        setLoggedIn,
        setIsLoading,
        userData,
        setUserData,
        openSnackbar,
        setOpenSnackbar,
        message,
        setMessage,
        severity,
        setSeverity,
        snackbar,
        homeLoader,
        setHomeLoader,
        callSessionId,
        setCallSessionId,
        resetCallSessionId,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
