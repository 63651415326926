import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Login from "./Login";
import Call from "./Call";
import Dashboard from "./Dashboard";
import Navbar from "../../components/NavBar";
import Sidebar from "../../components/SideBar";
import EmailParser from "./EmailParser";

import { SocketContextProvider } from "../../context/SocketContext";
import { CallContextProvider } from "../../context/CallContext";
import Logout from "./Logout";

const UsersRoutes = () => {
  return (
    <>
      {/* <Navbar /> */}
      <Sidebar>
        <SocketContextProvider>
          <CallContextProvider>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/mailparser" element={<EmailParser />} />
              <Route path="/callcenter" element={<Call />} />
              <Route path="/logout" element={<Logout />} />
            </Routes>
          </CallContextProvider>
        </SocketContextProvider>
      </Sidebar>
    </>
  );
};

export default UsersRoutes;
