import * as React from 'react';
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Grid, Button, Dialog, Divider, Checkbox, Stack, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2196f3",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(desc, play, record, del) {
    return { desc, play, record, del };
}



function CustomizedTables(props) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow hover>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell align="center">Play</StyledTableCell>
                        <StyledTableCell align="center">Record</StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, index) => (
                        <StyledTableRow key={index}
                            hover
                            role="checkbox"
                            aria-checked={props.index == index}
                            tabIndex={-1}
                            selected={props.index == index}
                            onClick={() => props.setIndex(index)}
                        >
                            <StyledTableCell component="th" style={{ width: 40 }} scope="row">
                                {index + 1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {row.desc}
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ width: 80 }}><a href="#">{row.play ? "Play" : ""}</a></StyledTableCell>
                            <StyledTableCell align="center" style={{ width: 80 }}><a href="#">{row.record ? "Record" : ""}</a></StyledTableCell>
                            <StyledTableCell align="center" style={{ width: 80 }}><a href="#">{row.del ? "Delete" : ""}</a></StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function AwayStatusDialog(props) {
    const { open, setOpen } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [showNew, setShowNew] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [desc, setDesc] = useState('');
    const [statusList, setStatusList] = useState([
        createData('Out Lunch', true, true, true),
        createData('Do Not Disturb', true, true, true),
        createData('Gone Fishing', true, true, true)
    ]);

    const handleClose = () => {
        setShowNew(false);
        setOpen(false);
    };

    const addNewStatus = () => {
        setStatusList([...statusList, createData(desc, true, true, true)]);
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Edit AWAY status"}</DialogTitle>
                <DialogContent>
                    <CustomizedTables index={currentStatus} setIndex={setCurrentStatus} rows={statusList} />
                    < Grid
                        container
                        direction="row"
                        justifyContent="center"
                        sx={{ mt: 3 }}
                    >
                        <Button variant="contained" color="error" sx={{ width: 120, mr: 1, borderRadius: 6 }} onClick={handleClose}>
                            Return
                        </Button>
                        <Button variant="contained" color="success" sx={{ width: 120, ml: 1, borderRadius: 6 }} onClick={() => setShowNew(true)}>
                            New
                        </Button>
                    </Grid>
                    {showNew && <Box sx={{ ml: 5 }}>
                        <Divider sx={{ ml: -5, mt: 4, mb: 4 }} />
                        Play message if operator is not logged in <Checkbox {...label} />
                        <br />
                        Transfer Call To Remote Phone <Checkbox {...label} />
                        <br />
                        <TextField
                            label="Phone Number"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            variant="standard"
                        />
                        <br />
                        <TextField
                            label="Description"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            variant="standard"
                            onChange={(e) => setDesc(e.target.value)}
                        />
                        <br />
                        <br />
                        <Button variant="contained" color="primary" sx={{ borderRadius: 6 }} size="small" onClick={() => addNewStatus()}>
                            Add
                        </Button>
                    </Box>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}