import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import RouterContent from "./routers/Router";
import { CallCenterContextProvider } from "./context/callCenterContext";
import { AuthContextProvider } from "./context/authContext";
import { DCContextProvider } from "./context/DCContext";
import palette from "./theme/palette";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <DCContextProvider>
          <CallCenterContextProvider>
            <ThemeProvider theme={palette}>
              <RouterContent />
            </ThemeProvider>
          </CallCenterContextProvider>
        </DCContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
