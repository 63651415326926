import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import Register from "../pages/Register";
import DC from "../pages/DC";
import useAuth from "../hook/useAuth";
import { useEffect } from "react";
import Loader from "../reusableComponent/isLoading/Loader";
import SnackbarComponent from "../reusableComponent/snackbarComponent/SnackbarComponent";
import useAuthApi from "../api/useAuthApi";

export default function RouterContent() {
  const {
    isLoading,
    loggedIn,
    setIsLoading,
    openSnackbar,
    setOpenSnackbar,
    message,
    severity,
    homeLoader,
  } = useAuth();
  const { dashboard } = useAuthApi();

  useEffect(() => {
    dashboard();
  }, []);

  return homeLoader ? (
    <></>
  ) : (
    <>
      <Loader open={isLoading} setOpen={setIsLoading} />
      <SnackbarComponent
        message={message}
        severity={severity}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />
      <Router>
        <div className="full-viewport">
          <Routes>
            {loggedIn && (
              <>
                <Route path="/" element={<DC />} />
              </>
            )}

            {!loggedIn ? (
              <>
                <Route path="/register" element={<Register />} />
                <Route path="/*" element={<Login />} />
              </>
            ) : (
              <Route path="/*" element={<DC />} />
            )}
          </Routes>
        </div>
      </Router>
    </>
  );
}
