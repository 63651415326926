import { useContext } from "react";
import { authContext } from "../context/authContext";

const useAuth = () => {
  const {
    isLoading,
    loggedIn,
    setLoggedIn,
    setIsLoading,
    userData,
    setUserData,
    openSnackbar,
    setOpenSnackbar,
    message,
    setMessage,
    severity,
    setSeverity,
    snackbar,
    homeLoader,
    setHomeLoader,
    callSessionId,
    setCallSessionId,
    resetCallSessionId,
  } = useContext(authContext);
  return {
    isLoading,
    callSessionId,
    setCallSessionId,
    loggedIn,
    setLoggedIn,
    setIsLoading,
    userData,
    setUserData,
    openSnackbar,
    setOpenSnackbar,
    message,
    setMessage,
    severity,
    setSeverity,
    snackbar,
    homeLoader,
    setHomeLoader,
    resetCallSessionId,
  };
};

export default useAuth;
