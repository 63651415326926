import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CallIcon from "@mui/icons-material/Call";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import SearchIcon from "@mui/icons-material/Search";
import useCallApi from "../../../api/useCallApi";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { audioPlayer } from "./audioPlayer";
const CallHistory = ({
  setDialNumber,
  handleDialing,
  setValue,
  history,
  total,
  page,
  setPage,
  setHistory,
  getHistory,
}) => {
  const [searchTestString, setSearchTestString] = useState("");
  const { searchCallHistoryApi } = useCallApi();

  const searchContactHandler = async (e) => {
    setSearchTestString(e.target.value);
    const response = await searchCallHistoryApi(e.target.value);
    setHistory(response.data);
  };
  const searchCloseHandler = async (event) => {
    setSearchTestString("");
    setPage(1);
    getHistory(page);
  };
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        paddingBottom: "65px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#eee",
        }}
      >
        <Typography variant="h5" textAlign="left" sx={{ padding: "10px 15px" }}>
          Call History
        </Typography>
      </Box>
      <TextField
        label="Search History"
        name="email"
        size="medium"
        sx={{ width: "90%", margin: "20px" }}
        inputProps={{ maxLength: 64 }}
        value={searchTestString}
        onChange={searchContactHandler}
        InputProps={{
          sx: { borderRadius: "300px" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="upload picture" component="label">
                {searchTestString && searchTestString.length !== 0 ? (
                  <CloseIcon onClick={searchCloseHandler} />
                ) : (
                  <SearchIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <List dense sx={{ width: "100%" }}>
        {history.map((item, index) => {
          const labelId = `checkbox-list-secondary-label-${item._id}`;
          const labelId2 = `checkbox-list-secondary-label2-${item._id}`;
          return (
            <div key={index}>
              <ListItem
                sx={{ cursor: "pointer" }}
                secondaryAction={
                  <CallIcon
                    onClick={() => {
                      setDialNumber(item.phone);
                      handleDialing(true, item.phone);
                      setValue(0);
                    }}
                  />
                }
              >
                <ListItemButton>
                  <ListItemAvatar>
                    {item.type === "inComing" ? (
                      <CallReceivedIcon sx={{ color: "blue" }} />
                    ) : item.type === "outGoing" ? (
                      <CallMadeIcon sx={{ color: "green" }} />
                    ) : item.type === "missCall" ? (
                      <CallMissedIcon sx={{ color: "red" }} />
                    ) : (
                      <></>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={
                      <Box>
                        {item.contact?.name ? (
                          <>
                            <Typography>{item.contact?.name}</Typography>{" "}
                            <Typography fontSize={10}>{item.phone}</Typography>
                            <Typography fontSize={10}>
                              {timeSince(new Date(item.createdAt))} ago
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography>{item.phone}</Typography>
                            <Typography fontSize={10}>
                              {timeSince(new Date(item.createdAt))} ago
                            </Typography>
                          </>
                        )}
                      </Box>
                    }
                  />
                  <ListItemText
                    id={labelId2}
                    primary={
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Typography>
                          {item.duration
                            ? timeSinceDuration(new Date(item.duration))
                            : true}
                        </Typography>
                        {item.recordingUrl ? (
                          <KeyboardVoiceIcon
                            sx={{ color: "#ff0000" }}
                            onClick={() => {
                              var myWindow = window.open(
                                "",
                                `My${item._id}${Math.floor(
                                  Math.random() * 100000
                                )}`,
                                "width=800,height=800"
                              );
                              myWindow.document.write(
                                audioPlayer(item.recordingUrl, item.phone)
                              );
                            }}
                          />
                        ) : (
                          <KeyboardVoiceIcon sx={{ visibility: "hidden" }} />
                        )}
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider variant="middle" />
            </div>
          );
        })}
      </List>
      <Pagination
        count={Math.trunc(total / 10) + 1}
        page={page}
        sx={{ position: "absolute", bottom: "20px" }}
        onChange={(a, pageNo) => {
          setPage(pageNo);
        }}
      />
    </Paper>
  );
};
function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
function timeSinceDuration(seconds) {
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
export default CallHistory;
