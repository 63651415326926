import * as React from 'react';
import { useState, useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Slide, Grid, Button, Dialog, Divider, Checkbox, Stack, TextField, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} sx={{ ml: 3, borderRadius: 6 }} size="small">
                Choose
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ p: 3 }}
            >
                <FormControl sx={{ m: 3 }}>
                    <FormLabel id="demo-radio-buttons-group-label">Groups</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value="outbound_group"
                    >
                        <FormControlLabel value="outbound_group" control={<Radio />} label="Outbound Group" />
                    </RadioGroup>
                </FormControl>
                <FormControl sx={{ m: 3 }}>
                    <FormLabel id="demo-radio-buttons-group-label">Operators</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="female" control={<Radio />} label="Mease Steve" />
                        <FormControlLabel value="male" control={<Radio />} label="Henry Philip" />
                    </RadioGroup>
                </FormControl>
            </Popover>
        </div>
    );
}

export default function ScheduledDialDialog(props) {
    const { open, setOpen } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const [showNew, setShowNew] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [desc, setDesc] = useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        console.log("popper clicked")
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const id = Boolean(anchorEl) ? 'simple-popper' : undefined;

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth={true}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Create a new schedule"}</DialogTitle>
                <DialogContent>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 3, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Scheduled by:
                        </Typography>
                        <TextField
                            label=""
                            id="standard-size-small"
                            defaultValue="Mease Steve"
                            value="Mease Steve"
                            size="small"
                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 3, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Scheduled for:
                        </Typography>
                        <TextField
                            label=""
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            variant="outlined"
                        />
                        <BasicPopover />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Phone number:
                        </Typography>
                        <TextField
                            label=""
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Redial interval:
                        </Typography>
                        <TextField
                            label=""
                            type="number"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"

                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Start Ring Date:
                        </Typography>
                        <TextField
                            label=""
                            type="datetime-local"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"

                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            Stop Ring Date:
                        </Typography>
                        <TextField
                            label=""
                            type="datetime-local"
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                        <Typography variant="subtitle1" component="h2" sx={{ mr: 2, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            Description
                        </Typography>
                        <TextField
                            label=""
                            id="standard-size-small"
                            defaultValue=""
                            size="small"
                            fullWidth={true}
                            multiline
                            rows={4}
                            variant="outlined"
                        />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row", mt: 3, justifyContent: "center" }}>
                        <Button variant="contained" color="error" sx={{ width: 120, mr: 1, borderRadius: 6 }} onClick={handleClose}>
                            Return
                        </Button>
                        <Button variant="contained" color="success" sx={{ width: 120, ml: 1, borderRadius: 6 }} onClick={() => handleClose()}>
                            Save
                        </Button>
                    </Stack>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions> */}
            </Dialog>
        </div >
    );
}